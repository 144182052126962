import { ISubject } from 'app/shared/model/subject.model';
import { IProject } from 'app/shared/model/project.model';

export interface ISite {
  id?: string;
  siteId?: string | null;
  siteName?: string | null;
  anonymizationByIRC?: boolean | null;
  comment?: string | null;
  subjects?: ISubject[] | null;
  project?: IProject | null;
}

export const defaultValue: Readonly<ISite> = {
  anonymizationByIRC: false,
};
