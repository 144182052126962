import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Author from './author';
import Project from './project';
import Site from './site';
import Subject from './subject';
import Timepoint from './timepoint';
import Task from './task';
import Report from './report';
import VerificationCode from './verification-code';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="author/*" element={<Author />} />
        <Route path="project/*" element={<Project />} />
        <Route path="site/*" element={<Site />} />
        <Route path="subject/*" element={<Subject />} />
        <Route path="timepoint/*" element={<Timepoint />} />
        <Route path="task/*" element={<Task />} />
        <Route path="report/*" element={<Report />} />
        <Route path="verification-code/*" element={<VerificationCode />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
