import { ITimepoint } from 'app/shared/model/timepoint.model';
import { ISite } from 'app/shared/model/site.model';

export interface ISubject {
  id?: string;
  subjectId?: string | null;
  disease?: string | null;
  history?: string | null;
  comment?: string | null;
  bOR?: ITimepoint | null;
  timepoints?: ITimepoint[] | null;
  site?: ISite | null;
}

export const defaultValue: Readonly<ISubject> = {};
