import dayjs from 'dayjs';

export interface IVerificationCode {
  id?: string;
  ts?: dayjs.Dayjs | null;
  code?: string | null;
  cellphone?: string | null;
}

export const defaultValue: Readonly<IVerificationCode> = {};
