import { ITask } from 'app/shared/model/task.model';
import { ISubject } from 'app/shared/model/subject.model';

export interface ITimepoint {
  id?: string;
  uID?: string | null;
  status?: string | null;
  cycle?: number | null;
  dataPath?: string | null;
  comment?: string | null;
  tasks?: ITask[] | null;
  subject?: ISubject | null;
}

export const defaultValue: Readonly<ITimepoint> = {};
