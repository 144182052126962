import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITask } from 'app/shared/model/task.model';
import { getEntities as getTasks } from 'app/entities/task/task.reducer';
import { IReport } from 'app/shared/model/report.model';
import { getEntity, updateEntity, createEntity, reset } from './report.reducer';

export const ReportUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const tasks = useAppSelector(state => state.task.entities);
  const reportEntity = useAppSelector(state => state.report.entity);
  const loading = useAppSelector(state => state.report.loading);
  const updating = useAppSelector(state => state.report.updating);
  const updateSuccess = useAppSelector(state => state.report.updateSuccess);

  const handleClose = () => {
    navigate('/report' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getTasks({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.reportVersion !== undefined && typeof values.reportVersion !== 'number') {
      values.reportVersion = Number(values.reportVersion);
    }
    values.createTime = convertDateTimeToServer(values.createTime);
    if (values.sOD !== undefined && typeof values.sOD !== 'number') {
      values.sOD = Number(values.sOD);
    }

    const entity = {
      ...reportEntity,
      ...values,
      task: tasks.find(it => it.id.toString() === values.task.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createTime: displayDefaultDateTime(),
        }
      : {
          ...reportEntity,
          createTime: convertDateTimeFromServer(reportEntity.createTime),
          task: reportEntity?.task?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="drApp.report.home.createOrEditLabel" data-cy="ReportCreateUpdateHeading">
            <Translate contentKey="drApp.report.home.createOrEditLabel">Create or edit a Report</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="report-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('drApp.report.username')}
                id="report-username"
                name="username"
                data-cy="username"
                type="text"
              />
              <ValidatedField
                label={translate('drApp.report.reportVersion')}
                id="report-reportVersion"
                name="reportVersion"
                data-cy="reportVersion"
                type="text"
              />
              <ValidatedField
                label={translate('drApp.report.createTime')}
                id="report-createTime"
                name="createTime"
                data-cy="createTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('drApp.report.reportInfo')}
                id="report-reportInfo"
                name="reportInfo"
                data-cy="reportInfo"
                type="text"
              />
              {/*<ValidatedField*/}
              {/*  label={translate('drApp.report.measurements')}*/}
              {/*  id="report-measurements"*/}
              {/*  name="measurements"*/}
              {/*  data-cy="measurements"*/}
              {/*  type="text"*/}
              {/*/>*/}
              <ValidatedField
                label={translate('drApp.report.reportTemplate')}
                id="report-reportTemplate"
                name="reportTemplate"
                data-cy="reportTemplate"
                type="text"
              />
              <ValidatedField
                label={translate('drApp.report.reportTemplateVersion')}
                id="report-reportTemplateVersion"
                name="reportTemplateVersion"
                data-cy="reportTemplateVersion"
                type="text"
              />
              <ValidatedField
                label={translate('drApp.report.reportComment')}
                id="report-reportComment"
                name="reportComment"
                data-cy="reportComment"
                type="text"
              />
              <ValidatedField label={translate('drApp.report.sOD')} id="report-sOD" name="sOD" data-cy="sOD" type="text" />
              <ValidatedField
                label={translate('drApp.report.response')}
                id="report-response"
                name="response"
                data-cy="response"
                type="text"
              />
              <ValidatedField
                label={translate('drApp.report.targetResponse')}
                id="report-targetResponse"
                name="targetResponse"
                data-cy="targetResponse"
                type="text"
              />
              <ValidatedField
                label={translate('drApp.report.nonTargetResponse')}
                id="report-nonTargetResponse"
                name="nonTargetResponse"
                data-cy="nonTargetResponse"
                type="text"
              />
              <ValidatedField id="report-task" name="task" data-cy="task" label={translate('drApp.report.task')} type="select">
                <option value="" key="0" />
                {tasks
                  ? tasks.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/report" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ReportUpdate;
