import { ISite } from 'app/shared/model/site.model';

export interface IProject {
  id?: string;
  codename?: string | null;
  title?: string | null;
  description?: string | null;
  sponsor?: string | null;
  phase?: string | null;
  criteria?: string | null;
  site?: string | null;
  subjectIdPattern?: string | null;
  disease?: string | null;
  testJson?: string | null;
  sites?: ISite[] | null;
}

export const defaultValue: Readonly<IProject> = {};
