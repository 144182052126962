import author from 'app/entities/author/author.reducer';
import project from 'app/entities/project/project.reducer';
import site from 'app/entities/site/site.reducer';
import subject from 'app/entities/subject/subject.reducer';
import timepoint from 'app/entities/timepoint/timepoint.reducer';
import task from 'app/entities/task/task.reducer';
import report from 'app/entities/report/report.reducer';
import verificationCode from 'app/entities/verification-code/verification-code.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  author,
  project,
  site,
  subject,
  timepoint,
  task,
  report,
  verificationCode,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
