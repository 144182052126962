import dayjs from 'dayjs';
import { ITask } from 'app/shared/model/task.model';

export interface IReport {
  id?: string;
  username?: string | null;
  reportVersion?: number | null;
  createTime?: dayjs.Dayjs | null;
  reportInfo?: string | null;
  measurements?: string | null;
  reportTemplate?: string | null;
  reportTemplateVersion?: string | null;
  reportComment?: string | null;
  sOD?: number | null;
  response?: string | null;
  targetResponse?: string | null;
  nonTargetResponse?: string | null;
  task?: ITask | null;
}

export const defaultValue: Readonly<IReport> = {};
