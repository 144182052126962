import { IReport } from 'app/shared/model/report.model';
import { ITimepoint } from 'app/shared/model/timepoint.model';

export interface ITask {
  id?: string;
  username?: string | null;
  status?: string | null;
  type?: string | null;
  comment?: string | null;
  reports?: IReport[] | null;
  timepoint?: ITimepoint | null;
}

export const defaultValue: Readonly<ITask> = {};
