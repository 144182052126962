import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './project.reducer';

export const ProjectDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const projectEntity = useAppSelector(state => state.project.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="projectDetailsHeading">
          <Translate contentKey="drApp.project.detail.title">Project</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{projectEntity.id}</dd>
          <dt>
            <span id="codename">
              <Translate contentKey="drApp.project.codename">Codename</Translate>
            </span>
          </dt>
          <dd>{projectEntity.codename}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="drApp.project.title">Title</Translate>
            </span>
          </dt>
          <dd>{projectEntity.title}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="drApp.project.description">Description</Translate>
            </span>
          </dt>
          <dd>{projectEntity.description}</dd>
          <dt>
            <span id="sponsor">
              <Translate contentKey="drApp.project.sponsor">Sponsor</Translate>
            </span>
          </dt>
          <dd>{projectEntity.sponsor}</dd>
          <dt>
            <span id="phase">
              <Translate contentKey="drApp.project.phase">Phase</Translate>
            </span>
          </dt>
          <dd>{projectEntity.phase}</dd>
          <dt>
            <span id="criteria">
              <Translate contentKey="drApp.project.criteria">Criteria</Translate>
            </span>
          </dt>
          <dd>{projectEntity.criteria}</dd>
          {/*<dt>*/}
          {/*  <span id="site">*/}
          {/*    <Translate contentKey="drApp.project.site">Site</Translate>*/}
          {/*  </span>*/}
          {/*</dt>*/}
          {/*<dd>{projectEntity.site}</dd>*/}
          <dt>
            <span id="subjectIdPattern">
              <Translate contentKey="drApp.project.subjectIdPattern">Subject Id Pattern</Translate>
            </span>
          </dt>
          <dd>{projectEntity.subjectIdPattern}</dd>
          <dt>
            <span id="disease">
              <Translate contentKey="drApp.project.disease">Disease</Translate>
            </span>
          </dt>
          <dd>{projectEntity.disease}</dd>
          <dt>
            <span id="testJson">
              <Translate contentKey="drApp.project.testJson">Test Json</Translate>
            </span>
          </dt>
          <dd>{projectEntity.testJson}</dd>
        </dl>
        <Button tag={Link} to="/project" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/project/${projectEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProjectDetail;
