import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import VerificationCode from './verification-code';
import VerificationCodeDetail from './verification-code-detail';
import VerificationCodeUpdate from './verification-code-update';
import VerificationCodeDeleteDialog from './verification-code-delete-dialog';

const VerificationCodeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<VerificationCode />} />
    <Route path="new" element={<VerificationCodeUpdate />} />
    <Route path=":id">
      <Route index element={<VerificationCodeDetail />} />
      <Route path="edit" element={<VerificationCodeUpdate />} />
      <Route path="delete" element={<VerificationCodeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default VerificationCodeRoutes;
