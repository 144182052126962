import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './report.reducer';

export const ReportDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const reportEntity = useAppSelector(state => state.report.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="reportDetailsHeading">
          <Translate contentKey="drApp.report.detail.title">Report</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{reportEntity.id}</dd>
          <dt>
            <span id="username">
              <Translate contentKey="drApp.report.username">Username</Translate>
            </span>
          </dt>
          <dd>{reportEntity.username}</dd>
          <dt>
            <span id="reportVersion">
              <Translate contentKey="drApp.report.reportVersion">Report Version</Translate>
            </span>
          </dt>
          <dd>{reportEntity.reportVersion}</dd>
          <dt>
            <span id="createTime">
              <Translate contentKey="drApp.report.createTime">Create Time</Translate>
            </span>
          </dt>
          <dd>{reportEntity.createTime ? <TextFormat value={reportEntity.createTime} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="reportInfo">
              <Translate contentKey="drApp.report.reportInfo">Report Info</Translate>
            </span>
          </dt>
          <dd>{reportEntity.reportInfo}</dd>
          {/*<dt>*/}
          {/*  <span id="measurements">*/}
          {/*    <Translate contentKey="drApp.report.measurements">Measurements</Translate>*/}
          {/*  </span>*/}
          {/*</dt>*/}
          {/*<dd>{reportEntity.measurements}</dd>*/}
          <dt>
            <span id="reportTemplate">
              <Translate contentKey="drApp.report.reportTemplate">Report Template</Translate>
            </span>
          </dt>
          <dd>{reportEntity.reportTemplate}</dd>
          <dt>
            <span id="reportTemplateVersion">
              <Translate contentKey="drApp.report.reportTemplateVersion">Report Template Version</Translate>
            </span>
          </dt>
          <dd>{reportEntity.reportTemplateVersion}</dd>
          <dt>
            <span id="reportComment">
              <Translate contentKey="drApp.report.reportComment">Report Comment</Translate>
            </span>
          </dt>
          <dd>{reportEntity.reportComment}</dd>
          <dt>
            <span id="sOD">
              <Translate contentKey="drApp.report.sOD">S OD</Translate>
            </span>
          </dt>
          <dd>{reportEntity.sOD}</dd>
          <dt>
            <span id="response">
              <Translate contentKey="drApp.report.response">Response</Translate>
            </span>
          </dt>
          <dd>{reportEntity.response}</dd>
          <dt>
            <span id="targetResponse">
              <Translate contentKey="drApp.report.targetResponse">Target Response</Translate>
            </span>
          </dt>
          <dd>{reportEntity.targetResponse}</dd>
          <dt>
            <span id="nonTargetResponse">
              <Translate contentKey="drApp.report.nonTargetResponse">Non Target Response</Translate>
            </span>
          </dt>
          <dd>{reportEntity.nonTargetResponse}</dd>
          <dt>
            <Translate contentKey="drApp.report.task">Task</Translate>
          </dt>
          <dd>{reportEntity.task ? reportEntity.task.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/report" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/report/${reportEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ReportDetail;
