import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITimepoint } from 'app/shared/model/timepoint.model';
import { getEntities as getTimepoints } from 'app/entities/timepoint/timepoint.reducer';
import { ISite } from 'app/shared/model/site.model';
import { getEntities as getSites } from 'app/entities/site/site.reducer';
import { ISubject } from 'app/shared/model/subject.model';
import { getEntity, updateEntity, createEntity, reset } from './subject.reducer';

export const SubjectUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const timepoints = useAppSelector(state => state.timepoint.entities);
  const sites = useAppSelector(state => state.site.entities);
  const subjectEntity = useAppSelector(state => state.subject.entity);
  const loading = useAppSelector(state => state.subject.loading);
  const updating = useAppSelector(state => state.subject.updating);
  const updateSuccess = useAppSelector(state => state.subject.updateSuccess);

  const handleClose = () => {
    navigate('/subject' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getTimepoints({}));
    dispatch(getSites({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    const entity = {
      ...subjectEntity,
      ...values,
      bOR: timepoints.find(it => it.id.toString() === values.bOR.toString()),
      site: sites.find(it => it.id.toString() === values.site.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...subjectEntity,
          bOR: subjectEntity?.bOR?.id,
          site: subjectEntity?.site?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="drApp.subject.home.createOrEditLabel" data-cy="SubjectCreateUpdateHeading">
            <Translate contentKey="drApp.subject.home.createOrEditLabel">Create or edit a Subject</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="subject-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('drApp.subject.subjectId')}
                id="subject-subjectId"
                name="subjectId"
                data-cy="subjectId"
                type="text"
              />
              <ValidatedField
                label={translate('drApp.subject.disease')}
                id="subject-disease"
                name="disease"
                data-cy="disease"
                type="text"
              />
              <ValidatedField
                label={translate('drApp.subject.history')}
                id="subject-history"
                name="history"
                data-cy="history"
                type="text"
              />
              <ValidatedField
                label={translate('drApp.subject.comment')}
                id="subject-comment"
                name="comment"
                data-cy="comment"
                type="text"
              />
              <ValidatedField id="subject-bOR" name="bOR" data-cy="bOR" label={translate('drApp.subject.bOR')} type="select">
                <option value="" key="0" />
                {timepoints
                  ? timepoints.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="subject-site" name="site" data-cy="site" label={translate('drApp.subject.site')} type="select">
                <option value="" key="0" />
                {sites
                  ? sites.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/subject" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SubjectUpdate;
