import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Timepoint from './timepoint';
import TimepointDetail from './timepoint-detail';
import TimepointUpdate from './timepoint-update';
import TimepointDeleteDialog from './timepoint-delete-dialog';

const TimepointRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Timepoint />} />
    <Route path="new" element={<TimepointUpdate />} />
    <Route path=":id">
      <Route index element={<TimepointDetail />} />
      <Route path="edit" element={<TimepointUpdate />} />
      <Route path="delete" element={<TimepointDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TimepointRoutes;
